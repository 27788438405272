import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountStoreFacade } from 'src/app/modules/account/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private accountFacade: AccountStoreFacade, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if ( this.accountFacade.isAuth) {
      return this.accountFacade.isAuth;
    } else {
      const token = localStorage.getItem('exchange-user');
      if (token) {
        this.accountFacade.setToken(token);
        return true;
      }
    }

    this.router.navigate(['/', 'account', 'login']);

    return false;
  }

}
