import { GlobalEntityState, IEntity } from '../../../../store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const USER_SESSION_STATE_KEY = 'userSessionState';

export interface UserSessionStateModel extends GlobalEntityState {
}

export const userSessionStateAdapter: EntityAdapter<IEntity> =
  createEntityAdapter<IEntity>({
    selectId: (entity) => entity.id,
  });
export const initialUserSessionState: UserSessionStateModel =
  userSessionStateAdapter.getInitialState({
    loading: false,
    page: 1,
    total: 0,
    entities: [],
  });
