import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoginPageComponent } from 'src/app/modules/account/pages/login-page/login-page.component';
import { AccountEffect, AccountStoreFacade, UserSessionEffect } from 'src/app/modules/account/store';

import { AccountPageRoutingModule } from './account-routing.module';
import { ACCOUNT_FEATURE_STATE_KEY, accountReducerMap } from './store/account-store.model';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AccountPageRoutingModule,
    StoreModule.forFeature(ACCOUNT_FEATURE_STATE_KEY, accountReducerMap),
    EffectsModule.forFeature([AccountEffect, UserSessionEffect]),
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [LoginPageComponent],
  providers: [AccountStoreFacade, HttpClient],
})
export class AccountPageModule {
  static forRoot(): ModuleWithProviders<AccountPageModule> {
    return {
      ngModule: AccountPageModule,
      providers: [AccountStoreFacade],
    };
  }
}
