/* eslint-disable quotes */
import { createAction, props } from '@ngrx/store';
import { QueryFilterRequest } from '../../../../interfaces/query-filter';
import { PageView } from '../../../../interfaces/page-view';
import { IEntity } from '../../../../store';

export const loadSession = createAction(
  `[user session] load`,
  props<{ props: QueryFilterRequest }>(),
);
export const loadSessionSuccess = createAction(
  `[user session] load success`,
  props<{ data: PageView<IEntity> }>(),
);
export const updateSession = createAction(
  `[user session] update`,
  props<{ id; session }>(),
);
export const updateSessionSuccess = createAction(
  `[user session] update success`,
  props<{ session }>(),
);
export const deleteSession = createAction(
  `[user session] delete`,
  props<{ id }>(),
);
export const deleteSessuonSuccess = createAction(
  `[user session] delete success`,
  props<{ id }>(),
);
