import { Component, OnInit } from '@angular/core';
import { AccountStoreFacade } from '../../../modules/account/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-current-user-logo',
  templateUrl: './current-user-logo.component.html',
  styleUrls: ['./current-user-logo.component.scss'],
})
export class CurrentUserLogoComponent implements OnInit {

  public authorize$: Observable<boolean>;
  public userName$: Observable<any>;

  constructor(private accountFacade: AccountStoreFacade) { }

  ngOnInit() {
    this.authorize$ = this.accountFacade.isAuth$;
    this.userName$ = this.accountFacade.currentUser$;
  }

}
