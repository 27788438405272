import {
  USER_SESSION_STATE_KEY,
  userSessionStateAdapter,
} from './session.model';
import { createSelector } from '@ngrx/store';
import { accountSelectState } from '../account-store.model';

export const getSessionState = createSelector(
  accountSelectState,
  (state) => state[USER_SESSION_STATE_KEY]
);

export const {
  selectAll: _selectAll,
  selectEntities: _selectEntities,
  selectIds: _selectIds,
  selectTotal: _selectTotal,
} = userSessionStateAdapter.getSelectors();

export const getElements = createSelector(getSessionState, _selectAll);
export const getEntities = createSelector(getSessionState, _selectEntities);
export const getIds = createSelector(getSessionState, _selectIds);
export const getTotal = createSelector(getSessionState, (state) => state.total);
export const getLoading = createSelector(
  getSessionState,
  (state) => state.loading
);
