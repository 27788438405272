import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentUserLogoComponent } from 'src/app/components/current-user/current-user-logo/current-user-logo.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [CurrentUserLogoComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
  ],
  exports: [
    CurrentUserLogoComponent,
  ],
})
export class CurrentUserModule {
}
