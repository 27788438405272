import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { currentUserReducerFn } from './account/account.reducer';
import { CURRENT_USER_STATE_KEY, CurrentUserState } from './account/account.model';
import { USER_SESSION_STATE_KEY, UserSessionStateModel } from './session/session.model';
import { userSessionReducerFn } from './session/session.reducer';

export const ACCOUNT_FEATURE_STATE_KEY = 'accountState';

export interface AccountState {
  [CURRENT_USER_STATE_KEY]: CurrentUserState;
  [USER_SESSION_STATE_KEY]: UserSessionStateModel;
}

export const accountReducerMap: ActionReducerMap<AccountState> = {
  [CURRENT_USER_STATE_KEY]: currentUserReducerFn,
  [USER_SESSION_STATE_KEY]: userSessionReducerFn,
};

export const accountSelectState = createFeatureSelector<AccountState>(
  ACCOUNT_FEATURE_STATE_KEY,
);
