export const NOTIFY_STATE_FEATURE_KEY = 'notifyState';
export type GlobalMessageType = 'success' | 'alert' | undefined;

export interface GlobalNotifyState {
  message: string;
  type: GlobalMessageType;
}

export const initialNotifyState: GlobalNotifyState = {
   type: undefined,
   message: undefined,
};
