import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountStoreFacade } from 'src/app/modules/account/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public confirmForm: FormGroup;
  public command$: Observable<string>;
  public verify: string;

  private destroySubject: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private formBuilder: FormBuilder,
    private accountFacade: AccountStoreFacade,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private router: Router,
  ) {
    this.loginForm = this.createLoginForm();
  }

  public get qrlink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.authenticatorapi.com/pair.aspx?AppName=C_ECXHANGE&AppInfo=user&SecretCode=${this.verify}`,
    );
  }

  ngOnInit() {
    this.command$ = this.accountFacade.command$;

    this.accountFacade.verify$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((verify) => {
        this.verify = verify;
      });

    this.command$.pipe(takeUntil(this.destroySubject)).subscribe((command) => {
      if (command && command === 'success') {
        this.router.navigate(['/']);
      }
    });
  }

  public onLoginClick(): void {
    this.accountFacade.login(this.loginForm.value);
    this.confirmForm = this.createConfirmForm();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  public onCopyToClipboard(): void {
    this.clipboard.copy(this.verify);
  }

  public onSetConfirm(): void {
    this.accountFacade.setCommand('signin');
    this.confirmForm = this.createConfirmForm();
  }

  public onConfirmClick(): void {
    this.accountFacade.confirm(this.confirmForm.value);
  }

  private createLoginForm(): FormGroup {
    const form = this.formBuilder.group({
      phone: [
        undefined,
        [Validators.required, Validators.pattern(/^[+]?[0-9]{12}$/g)],
      ],
    });

    return form;
  }

  private createConfirmForm(): FormGroup {
    const form = this.formBuilder.group({
      phone: [this.loginForm.controls.phone.value, [Validators.required]],
      code: [
        undefined,
        [Validators.required, Validators.maxLength(6), Validators.minLength(6)],
      ],
    });

    return form;
  }
}
