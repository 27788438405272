import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as accountAction from './account.action';

@Injectable()
export class AccountEffect {
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountAction.login),
      switchMap((action) =>
        this.http
          .post('/api/account/v1/account/login', action.payload)
          .pipe(
            map((response) => accountAction.loginSuccess({ payload: response })),
          ),
      ),
    ),
  );

  public confirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountAction.confirm),
      switchMap((action) =>
        this.http
          .post('/api/account/v1/account/confirm', action.payload)
          .pipe(
            map((response) =>
              accountAction.confirmSuccess({ payload: response }),
            ),
          ),
      ),
    ),
  );

  public loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountAction.loadCurrentUser),
      switchMap((action) =>
        this.http
          .get('/api/account/v1/userinformation')
          .pipe(
            map((response) =>
              accountAction.loadCurrentUserSuccess({ payload: response }),
            ),
          ),
      ),
    ),
  );

  constructor(private http: HttpClient, private actions$: Actions) {
  }
}
