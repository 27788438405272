import { isArray } from 'rxjs/internal/util/isArray';
import { isObject } from 'rxjs/internal/util/isObject';

export class QueryNetCore {
  static toQueryString(object: any): string {
    let params = '';
    const keys = Object.keys(object);
    keys.forEach((key) => {
      params += QueryNetCore.addParam(object[key], key);
    });
    return params;
  }

  static addParam(obj, prefix) {
    let param = '';
    if (isObject(obj) && !isArray(obj)) {
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        param += QueryNetCore.addParam(obj[key], `${prefix}.${key}`);
      });
    }
    if (isArray(obj)) {
      obj.forEach((x) => {
        param += `${prefix}=${x}&`;
      });
    }
    return param.length ? param : (prefix += `=${obj}&`);
  }
}
