import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as sessionAction from './session.action';
import { map, switchMap } from 'rxjs/operators';
import { QueryNetCore } from '../../../../operators/query-string-netcore';
import { PageView } from '../../../../interfaces/page-view';
import { IEntity } from '../../../../store';
import { createSelector } from '@ngrx/store';

@Injectable()
export class UserSessionEffect {
  public loadSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionAction.loadSession),
      switchMap((action) =>
        this.http
          .get(
            `/api/account/v1/session?${QueryNetCore.toQueryString(
              action.props,
            )}`,
          )
          .pipe(
            map((response: PageView<IEntity>) =>
              sessionAction.loadSessionSuccess({ data: response }),
            ),
          ),
      ),
    ),
  );

  public updateSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionAction.updateSession),
      switchMap((action) =>
        this.http
          .put(`/api/account/v1/session/${action.id}`, action.session)
          .pipe(
            map((response) =>
              sessionAction.updateSessionSuccess({ session: response }),
            ),
          ),
      ),
    ),
  );

  public deleteSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionAction.deleteSession),
      switchMap((action) =>
        this.http
          .delete(`/api/account/v1/session/${action.id}`)
          .pipe(
            map((response) =>
              sessionAction.deleteSessuonSuccess({ id: action.id }),
            ),
          ),
      ),
    ),
  );

  constructor(private http: HttpClient, private actions$: Actions) {
  }
}
