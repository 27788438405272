import { createReducer, on } from '@ngrx/store';
import * as sessionAction from './session.action';
import {
  initialUserSessionState,
  userSessionStateAdapter,
} from './session.model';

export const userSessionReducer = createReducer(
  initialUserSessionState,
  on(sessionAction.loadSession, (state, action) =>
    userSessionStateAdapter.removeAll({ ...state, loading: true }),
  ),
  on(sessionAction.loadSessionSuccess, (state, action) =>
    userSessionStateAdapter.setAll(action.data.elements, {
      ...state,
      loading: false,
      total: action.data.total,
      page: action.data.page,
    }),
  ),
  on(sessionAction.updateSession, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(sessionAction.updateSession, (state, action) =>
    userSessionStateAdapter.upsertOne(action.session, {
      ...state,
      loading: false,
    }),
  ),
  on(sessionAction.deleteSession, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(sessionAction.deleteSessuonSuccess, (state, action) =>
    userSessionStateAdapter.removeOne(action.id, { ...state, loading: false }),
  ),
);

export const userSessionReducerFn = (state, action) =>
  userSessionReducer(state, action);
