export const CURRENT_USER_STATE_KEY = 'currentUserState';

export interface CurrentUserState {
  user: any;
  token: any;
  command: string;
  verify: string;
  loading: boolean;
}

export const initialCurrentUser: CurrentUserState = {
  user: undefined,
  token: undefined,
  command: undefined,
  verify: undefined,
  loading: false,
};

