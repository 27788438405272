import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountStoreFacade } from 'src/app/modules/account/store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public isAuth$: Observable<boolean>;

  private destroySubject: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private accountFacade: AccountStoreFacade) {
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  ngOnInit(): void {
    this.accountFacade.token$.pipe(takeUntil(this.destroySubject)).subscribe((token) => {

      if (token && !this.accountFacade.token) {
        localStorage.setItem('exchange-user', token);
      }
      if (token && token !== this.accountFacade.token) {
        this.accountFacade.token = token;
        this.accountFacade.loadCurrentUser();
      }
    });

    this.accountFacade.isAuth$.pipe(takeUntil(this.destroySubject)).subscribe((auth) => {
      this.accountFacade.isAuth = auth;
    });

    this.isAuth$ = this.accountFacade.isAuth$;
  }


}
