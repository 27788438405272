import { createSelector, MemoizedSelector } from '@ngrx/store';
import {
  CURRENT_USER_STATE_KEY, CurrentUserState,
} from 'src/app/modules/account/store/account/account.model';
import { accountSelectState, AccountState } from '../account-store.model';


export const currentUserState: MemoizedSelector<AccountState,
  CurrentUserState> = createSelector(
  accountSelectState,
  (state) => state[CURRENT_USER_STATE_KEY],
);

export const getToken = createSelector(
  currentUserState,
  (state) => state.token,
);

export const getAuth = createSelector(
  currentUserState,
  (state) => !!state.token,
);

export const getUser = createSelector(currentUserState, (state) => state.user);

export const getCommand = createSelector(
  currentUserState,
  (state) => state.command,
);

export const getVerify = createSelector(
  currentUserState,
  (state) => state.verify,
);

export const getLoading = createSelector(
  currentUserState,
  (state) => state.loading,
);
