import { createAction, props } from '@ngrx/store';


export const login = createAction('[account] login', props<{ payload: any }>());
export const loginSuccess = createAction('[account] login success', props<{ payload: any }>());
export const confirm = createAction('[account] confirm', props<{ payload: any }>());
export const confirmSuccess = createAction('[account] confirm success', props<{ payload: any }>());
export const setCommand = createAction('[account] set command', props<{ command: string }>());
export const setToken = createAction('[account] set token', props<{ token: string }>());
export const loadCurrentUser = createAction('[account] load user');
export const loadCurrentUserSuccess = createAction('[account] load user success', props<{ payload: any }>());
