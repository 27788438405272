import { createReducer, on } from '@ngrx/store';
import * as accountAction from './account.action';
import { initialCurrentUser } from './account.model';

export const currentUserReducer = createReducer(
  initialCurrentUser,
  on(accountAction.login, (state, action) => ({
    ...state,
    user: undefined,
    token: undefined,
    command: undefined,
  })),
  on(accountAction.loginSuccess, (state, action) => ({
    ...state,
    command: action.payload.command,
    token: action.payload.token,
    verify: action.payload.verify,
  })),
  on(accountAction.confirm, (state, action) => ({
    ...state,
    token: undefined,
  })),
  on(accountAction.confirmSuccess, (state, action) => ({
    ...state,
    token: action.payload.token,
    command: action.payload.command,
  })),
  on(accountAction.setCommand, (state, action) => ({
    ...state,
    command: action.command,
  })),
  on(accountAction.setToken, (state, action) => ({
    ...state,
    token: action.token,
  })),
  on(accountAction.loadCurrentUser, (state, action) => ({
    ...state,
    loading: true,
    user: undefined,
  })),
  on(accountAction.loadCurrentUserSuccess, (state, action) => ({
    ...state,
    loading: false,
    user: action.payload,
  })),
);

export const currentUserReducerFn = (state, action) =>
  currentUserReducer(state, action);
