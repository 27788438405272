import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as accountAction from './account/account.action';
import * as accountSelector from './account/account.selector';
import * as sessionAction from './session/session.action';
import { IEntity } from '../../../store';
import * as sessionSelector from './session/session.selector';

@Injectable()
export class AccountStoreFacade {
  public currentUser$: Observable<string> = this.store.pipe(
    select(accountSelector.getUser),
  );
  public token$: Observable<string> = this.store.pipe(
    select(accountSelector.getToken),
  );
  public command$: Observable<string> = this.store.pipe(
    select(accountSelector.getCommand),
  );
  public isAuth$: Observable<boolean> = this.store.pipe(
    select(accountSelector.getAuth),
  );
  public verify$: Observable<string> = this.store.pipe(
    select(accountSelector.getVerify),
  );
  public loading$: Observable<boolean> = this.store.pipe(
    select(accountSelector.getLoading),
  );

  /**
   * Session selectors
   **/

  public sessions$: Observable<IEntity[]> = this.store.pipe(
    select(sessionSelector.getElements),
  );
  public sessionLoading$: Observable<boolean> = this.store.pipe(
    select(sessionSelector.getLoading),
  );

  public token: string;
  public isAuth: boolean;

  constructor(private store: Store) {
  }

  public login(loginModel): void {
    this.store.dispatch(accountAction.login({ payload: loginModel }));
  }

  public confirm(confirmModel): void {
    this.store.dispatch(accountAction.confirm({ payload: confirmModel }));
  }

  public setCommand(command: string): void {
    this.store.dispatch(accountAction.setCommand({ command }));
  }

  public setToken(token: string): void {
    this.store.dispatch(accountAction.setToken({ token }));
  }

  public loadCurrentUser(): void {
    this.store.dispatch(accountAction.loadCurrentUser());
  }

  public loadUserSession(): void {
    this.store.dispatch(sessionAction.loadSession({ props: { page: 1 } }));
  }

  public updateSession(session): void {
    this.store.dispatch(
      sessionAction.updateSession({ id: session.id, session }),
    );
  }

  public deleteSession(id): void {
    this.store.dispatch(sessionAction.deleteSession({ id }));
  }
}
