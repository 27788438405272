import { EntityState } from '@ngrx/entity';
import { GlobalNotifyState, NOTIFY_STATE_FEATURE_KEY } from 'src/app/store/notify/notify.state';

export { CommonStateFacade } from './facade/common-state.facade';

export interface IEntity {
  id: any;
}

export interface GlobalEntityState extends EntityState<IEntity> {
  loading: boolean;
  total: number;
  page: number;
}

export const COMMON_FEATURE_STATE_KEY = 'commonState';

export interface CommonState {
  [NOTIFY_STATE_FEATURE_KEY]: GlobalNotifyState;
}

export interface GlobalState {
  [COMMON_FEATURE_STATE_KEY]: CommonState;
}

/*export const globalReducers: ActionReducerMap<GlobalState> = {
  [COMMON_FEATURE_STATE_KEY]: <CommonState> {
    [NOTIFY_STATE_FEATURE_KEY]: notifyReducerFn,
  },
};*/
